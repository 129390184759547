import React from 'react'
import kebabCase from 'lodash/kebabCase'
import truncate from 'truncate-html'
import Markdown from 'react-markdown'

import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ArticleLink = ({ article }) => {
  const image = getImage(article.node.cover.localFile)

  return (
    <div className="card">
      {article.node.cover ?
        <div className="card-image ">
          <div className="image">
            <GatsbyImage
              image={image}
              alt={article.node.title}
            />
          </div>
        </div>
        : null
      }

      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-3">{article.node.title}</p>
            <p className="subtitle is-6">{article.node.published_at}</p>
          </div>
        </div>

        <div className="content">
          {article.node.content &&
            <Markdown
              className="mb-3"
              children={
                truncate(
                  article.node.content, 16, { byWords: true }
                )
              }
            />
          }
        </div>
        
        <div className="columns">
          {article.node.tags? (
            <div className="column">
              <div className="tags level-left">
                {article.node.tags.map((tag, i) => (
                  <Link
                    to={`/tags/${kebabCase(tag.name)}/`}
                    key={i}
                    className="tag is-link"
                  ><span>{tag.name}</span>
                  </Link>
                ))}
              </div>
            </div>
          ) : null}
          <div className="column is-4 is-flex is-justify-content-flex-end">
            <Link
              className="button is-info is-flex is-align-self-flex-end"
              to={`/blog/${article.node.slug}`}
            >
              Lees verder
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleLink
